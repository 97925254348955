@import "constants/style";

.toggle-button {
  position: absolute;
  width: 40px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.toggle-button:disabled {
  cursor: not-allowed;
}
