@import "constants/style";

.loading-screen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

[theme="dark"] {
  .loading-screen {
    background-color: $color-dark-one;
  }
}
