@import "constants/style.scss";

.cookie-notice {
  background: $color-white;
  color: $color-black;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $padding--default;
  box-shadow: 0 -4px 16px rgba($color-backlog-blue, 0.16);
}

.cookie-notice__buttons {
  display: flex;
}

.cookie-notice__button {
  border: none;
  padding: $padding--small $padding--default;
  border-radius: 5px;
  font-size: $text-size--medium;
  cursor: pointer;

  &-accept {
    @extend .cookie-notice__button;
    background: $color-backlog-blue;
    color: $color-white;
    margin: 0 0 $margin--default $margin--default;
  }
  &-decline {
    @extend .cookie-notice__button;
    background: $color-lighter-gray;
    color: $color-darkest-gray;
    margin: 0 0 $margin--default $margin--default;
  }
  &-cookie-policy {
    @extend .cookie-notice__button;
    background: $color-lighter-gray;
    color: $color-darkest-gray;
    margin: 0 auto $margin--default 0;
  }
}

.cookie-notice__button:focus-visible,
.cookie-notice__button:hover {
  transform: scale(1.05);
}

[theme="dark"] {
  .cookie-notice {
    box-shadow: 0 -4px 16px rgba($color-black, 0.48);
  }
}
