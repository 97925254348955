// @use "sass:list";
@use "sass:color";

// base colors
$color-white: #ffffff;
$color-white-one: #f9fafb;
$color-white-two: #f6f5fb;
$color-dark-one: #242c3d;
$color-dark-two: #272f41;
$color-lighter-gray: #edeff2;
$color-light-gray: #c3c9d3;
$color-middle-gray: #a3a6aa;
$color-dark-gray: #859093;
$color-darker-gray: #4f5253;
$color-darkest-gray: #232323;
$color-black: #111111;

// element colors
$color-dark-mode: #272f41;
$color-dark-mode--disabled: #1b202d;
$color-dark-mode-note: #313949;
$color-dark-mode-note--hover: #3d4555;
$menu-icon-background-color--dark: #333948;
$menu-icon-background-color--light: $color-white;
$tooltip-background-color--light: #d3daf0;
$tooltip-background-color--dark: #485064;
$color-progress-circle: #3c7fff;
$color-icon-light-blue: #9ebfff;

// theme colors
$color-backlog-blue: #0057ff;
$color-grooming-green: #18d8ab;
$color-goal-green: #70e000;
$color-lean-lilac: #c000ff;
$color-online-orange: #ffaa5a;
$color-planning-pink: #e20360;
$color-poker-purple: #5e00ff;
$color-retro-red: #ea434b;
$color-warning-red: #eb625b;

@mixin rgb($color) {
  --accent-color: #{$color};
  --accent-color-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
  --accent-color--dark: #{color.change($color, $lightness: 72%, $saturation: 99%)};
  --accent-color--desaturated-light: #{color.change($color, $lightness: 90%)};
  --accent-color--desaturated-dark: #{color.change($color, $lightness: 39%, $saturation: 48%)};
}

.accent-color__backlog-blue {
  @include rgb($color-backlog-blue);
}
.accent-color__grooming-green {
  @include rgb($color-grooming-green);
}
.accent-color__goal-green {
  @include rgb($color-goal-green);
}
.accent-color__lean-lilac {
  @include rgb($color-lean-lilac);
}
.accent-color__online-orange {
  @include rgb($color-online-orange);
}
.accent-color__planning-pink {
  @include rgb($color-planning-pink);
}
.accent-color__poker-purple {
  @include rgb($color-poker-purple);
}
.accent-color__retro-red {
  @include rgb($color-retro-red);
}

// hover colors
$hover-background-light: #efefef;
$hover-background-dark: #414959;

// placeholder text colors
$color-placeholder: #1010104d;
$color-placeholder-dark: #ffffff4d;

// board & column constants
$board__side-panel-width: 64px;
$header__height: 100px;
$column__min-width: 300px;
$column__max-width: 960px;
$column__border-width: 12px;
$action-bar__width: 64px;

// icons
$icon--small: 16px;
$icon--medium: 20px;
$icon--large: 32px;
$icon--extralarge: 44px;
$icon--huge: 64px;

// border-radius
$note__border-radius: 16px;
$border-radius--round: 50px;

// margin and padding
$margin--small: 8px;
$margin--default: 16px;
$margin--medium: 24px;
$margin--large: 32px;
$padding--small: 8px;
$padding--default: 16px;
$padding--medium: 24px;
$padding--large: 32px;

// font
$letter-spacing--small: 0.25px;
$letter-spacing--medium: 0.35px;
$letter-spacing--large: 0.75px;
$text-size--small: 12px;
$text-size--medium: 16px;
$text-size--large: 20px;
$line-height--small: 16px;
$line-height--medium: 20px;
$line-height--large: 32px;

// filters
$darken--slightly: brightness(0.9);
$brighten--slightly: brightness(1.1);
$brighten--strongly: brightness(1.4);

// z-index
$base-z-index: 100;
$base-z-index-step: 10;
$note-z-index: $base-z-index + (2 * $base-z-index-step);
$menu-z-index: $base-z-index + (8 * $base-z-index-step);
$menu-item-z-index: $menu-z-index + $base-z-index-step;
$board__navigation-button-z-index: $base-z-index + (6 * $base-z-index-step);
$backdrop-z-index: $base-z-index + (9 * $base-z-index-step);
$note-dialog-z-index: $base-z-index + (6 * $base-z-index-step);
$infobar-z-index: $base-z-index + (5 * $base-z-index-step);
$request-z-index: $base-z-index + (10 * $base-z-index-step);

// responsive
$mini-smartphone: "screen and (max-width: 480px)";
$smartphone: "screen and (max-width: 767px)";
$tablet: "screen and (min-width : 768px)";
$desktop: "screen and (min-width : 1280px)";
$menu-mobile: "screen and (max-width: 1343px)";
$menu-desktop: "screen and (min-width: 1344px)";

// helper functions
@function inset-border($left: false, $right: false, $top: false, $bottom: false, $color: var(--accent-color)) {
  $props: ();
  $props: append($props, if($left, inset #{2 * $column__border-width} 0 0 #{-$column__border-width} #{$color}, null), comma);
  $props: append($props, if($right, inset #{-2 * $column__border-width} 0 0 #{-$column__border-width} #{$color}, null), comma);
  $props: append($props, if($top, inset 0 #{2 * $column__border-width} 0 #{-$column__border-width} #{$color}, null), comma);
  $props: append($props, if($bottom, inset 0 #{-2 * $column__border-width} 0 #{-$column__border-width} #{$color}, null), comma);
  @return $props;
}

@mixin inset-border($left: false, $right: false, $top: false, $bottom: false, $color: var(--accent-color)) {
  box-shadow: inset-border($left, $right, $top, $bottom, $color);
}

// scrollbar
@mixin scrollbar($width: 10px, $border-radius: 8px) {
  &::-webkit-scrollbar {
    width: #{$width};
  }

  &::-webkit-scrollbar-track {
    border-radius: #{$border-radius};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: #{$border-radius};
    background-color: rgba($color-light-gray, 0.3);
  }

  [theme="dark"] & {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-black, 0.3);
    }
  }
}
