@import "src/constants/style";
@import "src/components/Note/Note.scss";
@import "src/components/TooltipButton/TooltipButton.scss";
@import "src/components/Votes/Votes.scss";

.note-dialog__note-footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--note-footer-height);
  grid-area: 2 / 1 / 3 / 3;
}

.note-dialog__note-author {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  height: 35px;
  // reset css from figure-element:
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.note-dialog__note-author-image {
  height: $note__author-image-size;
  width: $note__author-image-size;
  border-radius: 100%;
}

.note-dialog__note-author-name {
  margin-left: $margin--small;
  color: $color-dark-gray;
  font-size: $text-size--small;
  letter-spacing: $letter-spacing--small;
  line-height: $line-height--small;
}

.note-dialog__note-user-avatar {
  height: var(--note-footer-height);
  width: var(--note-footer-height);
  padding: 0;
  margin-bottom: 4px;

  #Circle-Background {
    stroke: none;
  }
}

.note-dialog__note-author {
  display: inline-flex;
  align-items: center;
  // reset css from figure-element:
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  flex-grow: 0;
  height: 35px;
  padding: 0 $padding--default 0 0;
}

.note-dialog__note-author--self {
  background: var(--accent-color--desaturated-light);
  border-radius: 21px;
  padding-left: 2px;
  height: var(--note-footer-height);

  .note-dialog__note-author-name {
    color: var(--accent-color);
    font-weight: bold;
  }
}

.note-dialog__note-votes > *:last-child {
  margin: 0 calc(($tooltip-button-size - var(--note-footer-height, $votes-button-size)) / 2);
}

[theme="dark"] {
  .note-dialog__note-author--self {
    background: var(--accent-color--desaturated-dark);

    .note-dialog__note-author-name {
      color: $color-white;
    }
  }
}
