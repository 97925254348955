@import "constants/style";

@media #{$tablet} {
  #portal {
    .timer-dialog {
      top: calc(50% - 105px - 25px + 46px);
      right: 100px;
      left: auto;
      bottom: auto;
      height: auto;
    }

    .timer-dialog::before {
      content: " ";
      position: absolute;
      left: 100%;
      top: 10px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent transparent $color-planning-pink;
    }

    .timer-dialog::after {
      content: " ";
      position: absolute;
      left: 100%;
      top: 15px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent $color-white-one;
    }
  }

  [theme="dark"] {
    #portal .timer-dialog::after {
      border-color: transparent transparent transparent $color-dark-mode;
    }
  }
}

.timer-dialog__time-button {
  height: $icon--large;
  width: $icon--large;
  border: none;
  border-radius: 16px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 8px;
  padding: 0;
  transition: all 0.08s ease-out;
  outline: none;

  &:first-of-type {
    right: 80px;
  }

  &:last-of-type {
    right: 24px;
  }

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }
}

.timer-dialog__time-label {
  position: absolute;
  top: 8px;
  right: 56px;
  height: 32px;
  width: 24px;
  text-align: center;
  line-height: 32px;
  cursor: col-resize;
  font-size: $text-size--medium;
  user-select: none;
  -webkit-touch-callout: none;
  -webit-user-select: none;
}

.timer-dialog__button-icon {
  position: absolute;
  top: 8px;
  right: 24px;
}
