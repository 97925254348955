@import "constants/style.scss";

.timer {
  position: relative;
  width: auto;
}

.timer > * {
  color: $color-white;
  background-color: $color-backlog-blue;
  vertical-align: bottom;
  box-shadow: 0 8px 16px #0057ff25;
}

[theme="dark"] {
  .timer > * {
    background-color: $color-planning-pink;
    box-shadow: 0 8px 16px #e2036025;
  }
}

.timer > span {
  grid-column: 2;
  display: inline-block;
  border-radius: 18px;
  width: 84px;
  cursor: default;
  font-weight: bold;
  line-height: $line-height--large;
  text-align: center;
}

.timer > button {
  margin-left: $margin--small;
  border: none;
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 18px;
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }
}

// Shake animation on expired timer
.timer--expired > span {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
@media (prefers-reduced-motion: reduce) {
  .timer--expired > span {
    animation: none;
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
