@import "src/constants/style.scss";

.export-board__button-reverse-order {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.export-board__button-print-view {
  svg {
    height: $icon--medium;
  }
}

[theme="dark"] {
  .export-board__button-reverse-order svg g g {
    opacity: 1;
  }
  .export-board__button-reverse-order svg text {
    fill: $color-white;
    stroke: $color-white;
  }
}
