@import "constants/style.scss";

$logo-height: 60px;
$text-size--medium: 24px;

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.not-found__logo {
  height: $logo-height;
}

.not-found__text {
  color: $color-black;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--medium;
}
[theme="dark"] .not-found__text {
  color: $color-white;
}
