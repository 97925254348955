@import "constants/style";

.confirmation-dialog__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  min-width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  z-index: $backdrop-z-index;
  background: $color-white;
  padding: 0px 2rem;
}

@media screen and (max-width: 920px) {
  .confirmation-dialog__wrapper {
    padding: $padding--medium;
  }
}

.confirmation-dialog {
  max-width: 420px;
  color: $color-black;
}

.confirmation-dialog__button {
  margin-right: $margin--small;
}

[theme="dark"] {
  .confirmation-dialog__wrapper {
    background: $color-dark-mode;
  }
  .confirmation-dialog {
    color: $color-white;
  }
}
