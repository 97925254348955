@import "~@fontsource/raleway/300.css";
@import "~@fontsource/raleway/400.css";
@import "~@fontsource/raleway/500.css";
@import "~@fontsource/raleway/600.css";
@import "~@fontsource/raleway/700.css";
@import "~@fontsource/raleway/800.css";

@import "constants/style.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  background: $color-white;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "Raleway", sans-serif;
  font-variant-numeric: lining-nums;
}

#root {
  position: relative;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.toast-container__container {
  --toastify-icon-color-info: #{$color-backlog-blue};
  --toastify-icon-color-success: #{$color-grooming-green};
  --toastify-icon-color-warning: #{$color-online-orange};
  --toastify-icon-color-error: #{$color-warning-red};
}

.toast-container__container .toast-container__toast {
  margin-bottom: 0;
  font-size: $text-size--medium;

  background: $color-white;
  color: $color-black;
}

.toast-container__container .toast-container__toast + .toast-container__toast {
  margin-top: $margin--small;
}

[theme="dark"] {
  .toast-container__container .toast-container__toast {
    background: $color-dark-mode-note;
    color: $color-white;
  }

  body,
  #root {
    background: $color-dark-mode;
  }
}

html[theme="dark"] {
  background: $color-dark-mode;
}
