@import "src/constants/style";

.text-input-label {
  display: block;
}

.text-input-label__label {
  display: block;
  width: 100%;

  margin-left: 6px;
  margin-bottom: 4px;

  font-weight: bold;
  font-size: $text-size--medium;
}
