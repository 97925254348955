@import "constants/style";

@media #{$tablet} {
  #portal {
    .voting-dialog {
      top: calc(50% - 25px);
      right: 100px;
      bottom: auto;
      left: auto;
      height: auto;
    }
    .voting-dialog::before {
      content: " ";
      position: absolute;
      left: 100%;
      top: 10px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent transparent $color-planning-pink;
    }
    .voting-dialog::after {
      content: " ";
      position: absolute;
      left: 100%;
      top: 15px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent $color-white-one;
    }
  }

  [theme="dark"] {
    #portal .voting-dialog::after {
      border-color: transparent transparent transparent $color-dark-mode;
    }
  }
}

.voting-dialog__start-button {
  border: none;
  border-radius: 8px;
  height: 48px;
  width: 80%;
  background: $color-planning-pink;
  cursor: pointer;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.05);
    transform: scale(1.01);
    box-shadow: 0 4px 8px 0 rgba(var(--accent-color-rgb), 0.24);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    outline: 2px solid rgba(var(--accent-color-rgb), 0.4);
  }
}

.voting-dialog__start-button > label {
  font-weight: bold;
  font-size: $text-size--medium;
  color: $color-white;
  letter-spacing: $letter-spacing--medium;
  cursor: pointer;
}

.voting-dialog__toggle {
  position: absolute;
  top: 12px;
  right: 24px;
}

.voting-dialog__vote-button {
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 16px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 8px;
  padding: 0;
  transition: all 0.08s ease-out;
  outline: none;

  &:first-of-type {
    right: 80px;
  }

  &:last-of-type {
    right: 24px;
  }

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }
}

.voting-dialog__vote-label {
  position: absolute;
  top: 8px;
  right: 56px;
  height: 32px;
  width: 24px;
  text-align: center;
  line-height: 32px;
  font-size: $text-size--medium;
  cursor: col-resize;
  user-select: none;
  -webkit-touch-callout: none;
  -webit-user-select: none;
}

[theme="dark"] {
  .voting-dialog__start-button:focus-visible {
    outline: 2px solid $color-white;
  }
}
