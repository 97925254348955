@import "constants/style";

.avatar {
  width: 48px;
  height: 48px;
  overflow: visible;
}

.avatar #Circle-Background,
.avatar #Color\/Palette\/Blue-01 {
  fill: var(--accent-color);
}
