@import "constants/style.scss";

.info-bar {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: auto;
  top: $header__height;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  z-index: $infobar-z-index;
}

.info-bar > *:not(:last-child) {
  margin-right: $margin--default;
}

.info-bar > *:only-child {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.info-bar__return-to-focused-note-button > svg {
  background-color: $menu-icon-background-color--dark;
  color: $color-white;
}
[theme="dark"] .info-bar__return-to-focused-note-button > svg {
  background-color: $color-white;
  color: $menu-icon-background-color--dark;
}
