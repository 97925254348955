@import "constants/style.scss";

.participant {
  display: flex;
  background-color: $color-white;
  height: 48px;
  outline: 0;

  padding: 0;
  width: 100%;

  align-items: center;
  justify-content: space-between;

  &:hover {
    filter: $darken--slightly;
  }

  &:focus-within,
  &:focus-visible {
    background-color: $color-white-two;
  }
}

.participant:not(:last-child)::after {
  content: "";
  height: 1px;
  width: calc(100% - 48px);
  background-color: rgba(35, 35, 35, 0.1);
  position: absolute;
  bottom: 0;
  left: 48px;
}

.participant__avatar-and-name {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $padding--default 0 0;
  margin: 0;
  flex: 1 1 100%;
  overflow: hidden;
}

.participant .participant__user-avatar-wrapper {
  margin: 0 $margin--default 0 $margin--default;
  height: 28px;
  width: 28px;
}

.participant .participant__user-avatar {
  height: 28px;
  width: 28px;
}

.participant__name {
  display: inline-block;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--small;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $color-black;
}

.participant .participant__permission-toggle {
  position: relative;
  flex: 0 0 40px;
  margin-right: $margin--default;
  outline: none;
}

.participant .participant__permission-toggle:focus-visible::after,
.participant .participant__permission-toggle:hover::after {
  transform: scale(1.2);
}

[theme="dark"] {
  .participant {
    background-color: $color-dark-mode-note;

    &:hover {
      filter: $brighten--slightly;
    }

    &:focus-visible {
      background-color: $color-dark-mode-note--hover;
    }
  }
  .participant:not(:last-child)::after {
    background-color: rgba(125, 125, 125, 0.1);
  }
  .participant__name {
    color: $color-white;
  }
}
