@import "constants/style";

$header-menu-color--light: $color-backlog-blue;
$header-menu-color--dark: $color-planning-pink;
$header-menu-width: 288px;
$header-menu__item-height: 48px;

.header-menu {
  width: 288px;
  border-radius: 16px;
  box-shadow: 0 16px 32px 0 rgba(0, 87, 255, 0.24);
  overflow: hidden;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
[theme="dark"] {
  .header-menu {
    background-color: $header-menu-color--dark;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  }
}
@media #{$tablet} {
  .header-menu {
    margin-top: -20px;
  }
}
