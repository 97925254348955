@import "constants/style";
@import "components/Column/Column";

.board {
  --n: 1;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns:
    minmax($board__side-panel-width, 1fr)
    repeat(var(--board__columns), Min(calc((100% - #{$board__side-panel-width * 2}) / min(var(--board__columns), var(--n))), #{$column__max-width}))
    minmax($board__side-panel-width, 1fr);

  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  scroll-padding: $board__side-panel-width;

  width: 100vw;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  overflow: hidden;
  overflow-x: scroll;
}
.board::-webkit-scrollbar {
  display: none;
}

.board > * {
  scroll-snap-align: start;
}

.board__spacer-left {
  @include inset-border($top: true, $left: true, $bottom: true);
}
.board__spacer-right {
  @include inset-border($top: true, $right: true, $bottom: true);
}

.board__spacer-left:nth-child(odd),
.board__spacer-right:nth-child(odd) {
  background-color: $color-white-two;
}

.board__spacer-left:nth-child(even),
.board__spacer-right:nth-child(even) {
  background-color: $color-white-one;
}

[theme="dark"] {
  .board__spacer-left:nth-child(odd),
  .board__spacer-right:nth-child(odd) {
    background-color: $color-dark-one;
  }

  .board__spacer-left:nth-child(even),
  .board__spacer-right:nth-child(even) {
    background-color: $color-dark-two;
  }
}

@media screen and (max-width: $column__min-width+2*$board__side-panel-width) {
  .board {
    grid-template-columns:
      $column__border-width repeat(var(--board__columns), calc(100% - #{$column__border-width * 2}))
      $column__border-width;
    scroll-padding: $column__border-width;
  }
}

@for $i from 1 through 16 {
  @media screen and (min-width: $i*($column__min-width+2*$board__side-panel-width)) {
    .board {
      --n: #{$i};
    }
  }
}
