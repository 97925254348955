@import "constants/style";

.join-request {
  position: fixed;
  min-width: 300px;
  max-width: 480px;
  border-radius: 8px;
  top: 60px;
  left: 10px;
  right: 10px;
  overflow: hidden;
  animation: fly-in-top 0.5s ease-out;
  box-shadow: 0 16px 32px 0 rgba(0, 87, 255, 0.24);
  z-index: $request-z-index;
}

@media screen and (min-width: 420px) {
  .join-request {
    left: calc(50% - 200px);
    width: 480px;
  }
}
@media #{$tablet} {
  .join-request {
    right: 92px;
    left: auto;
    animation: fly-in-right 0.5s ease-out;
  }
}
[theme="dark"] {
  .join-request {
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.2);
  }
}

.request__button {
  font-size: $text-size--medium;
  font-weight: 600;
  letter-spacing: $letter-spacing--small;
  line-height: 24px;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: $color-backlog-blue;
  cursor: pointer;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.06);
  }
}

.request__button + .request__button {
  margin-left: $margin--default;
}

[theme="dark"] {
  .request__button {
    color: $color-planning-pink;
  }
}

.request__header {
  height: 42px;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--small;
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  padding-left: $padding--default;
  color: $color-white;
  background-color: $color-backlog-blue;
  overflow-wrap: break-word;
  white-space: nowrap;
}

[theme="dark"] {
  .request__header {
    background-color: $color-planning-pink;
  }
}

@media screen and (min-width: 375px) {
  .request__header {
    font-size: $text-size--medium;
  }
}

.request__main {
  width: 100%;
  height: auto;
  background-color: $color-white;
  border-radius: 0;
}

[theme="dark"] {
  .request__main {
    background-color: $color-dark-mode-note;
  }
}

.join-request__unique-request-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 $margin--default;
}

.join-request__unique-request-container + .join-request__unique-request-container {
  margin-top: 4px;
}

.join-request__request-figure {
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  vertical-align: baseline;
}

.join-request__request-name {
  margin-left: $margin--default;
  color: $color-black;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--small;
  line-height: $line-height--medium;
}
[theme="dark"] {
  .join-request__request-name {
    color: $color-white;
  }
}
.join-request__request-image {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.request__requests-preview {
  height: 64px;
  display: flex;
  flex-direction: row;
}
.join-request__preview-names {
  line-height: 64px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color-black;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--small;
  margin: 0 $margin--default;
}
[theme="dark"] {
  .join-request__preview-names {
    color: $color-white;
  }
}
.join-request__preview-image {
  height: 42px;
  width: 42px;
  border: 2px solid $color-white;
  border-radius: 100%;
  position: relative;
  top: 9px;
}
[theme="dark"] {
  .join-request__preview-image {
    border-color: $color-dark-mode-note;
  }
}
.join-request__preview-image:first-child {
  z-index: 7;
  margin-left: $margin--default;
}
.join-request__preview-image:nth-child(2) {
  z-index: 6;
  margin-left: -8px;
}

.join-request__preview-rest {
  height: 39px;
  min-width: 39px;
  max-width: 39px;
  outline: 2px solid $color-white;
  border: 1px solid $color-black;
  border-radius: 100%;
  margin-top: $margin--default;
  position: relative;
  margin-left: -8px;
  text-align: center;
  line-height: 37px;
  font-size: $text-size--medium;
  color: $color-black;
}
[theme="dark"] {
  .join-request__preview-rest {
    outline-color: $color-dark-mode-note;
    border-color: $color-white;
    color: $color-white;
  }
}

.request__requests {
  list-style-type: none;
  margin: 0;
  padding: $padding--default 0;
  max-height: 130px;
  overflow-y: auto;
}
[theme="dark"] {
  .request__requests {
    border-color: rgba(212, 217, 221, 0.2);
  }
}

.join-request__request-display-name {
  height: 18px;
  color: $color-black;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--small;
  line-height: $line-height--medium;
  width: 144px;
  margin: 0 $margin--default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
@media screen and (max-width: 375px) {
  .join-request__request-display-name {
    width: 96px;
  }
}
[theme="dark"] {
  .join-request__request-display-name {
    color: $color-white;
  }
}

.request__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 $padding--default;

  background-color: $color-white;
  height: 48px;
}

[theme="dark"] {
  .request__footer {
    background-color: $color-dark-mode-note;
  }
}

@keyframes fly-in-top {
  0% {
    top: -200px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    top: 60px;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fly-in-right {
  0% {
    right: -500px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    right: 92px;
    opacity: 1;
    visibility: visible;
  }
}
