@import "constants/style";

.board-header {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 10px;
}

.board-header__link {
  position: absolute;
  left: 0;
  top: 20px;
  padding: $padding--small $padding--large;
  border-radius: 16px;
  width: max-content;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  outline: none;
  background-color: transparent;
  border: none;

  &:focus-visible {
    box-shadow: 0 0 0 2px rgba($color-backlog-blue, 0.32);
  }

  &:hover {
    background-color: rgba($color-backlog-blue, 0.06);
  }
}

[theme="dark"] {
  .board-header__link {
    &:focus-visible {
      box-shadow: 0 0 0 2px rgba($color-planning-pink, 0.48);
    }

    &:hover {
      background-color: rgba($color-planning-pink, 0.15);
    }
  }
}

.board-header__name-and-settings {
  margin: $margin--default auto 0;
}

.board-header__users {
  position: absolute;
  right: $margin--large;
  top: calc(24px - $padding--small);
  padding: $padding--small;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: none;
  border: none;
  outline: none;
}

.board-header__share-button {
  color: $color-backlog-blue;
  outline: none;
  border: 2px solid $menu-icon-background-color--dark;
  color: $menu-icon-background-color--dark;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  margin-left: $margin--default;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.08s ease-out;
  background: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }
}
.board-header__share-button > svg {
  height: 36px;
  width: 36px;
}

[theme="dark"] {
  .board-header__share-button {
    border: 2px solid $color-icon-light-blue;
    color: $color-icon-light-blue;

    &:hover,
    &:focus-visible {
    }
  }
}

.board-header__name-and-settings {
  display: flex;
  justify-content: center;
}

.board-header_name-and-settings-button {
  width: max-content;
  padding: $padding--small $padding--large;
  border-radius: 16px;
  transition: background-color 0.2s ease-in-out;

  outline: 0;
  border: 0;
  background: none;

  cursor: pointer;

  &:focus-visible {
    box-shadow: 0 0 0 2px rgba($color-backlog-blue, 0.32);
  }

  &:hover {
    background-color: rgba($color-backlog-blue, 0.06);
  }
}

[theme="dark"] {
  .board-header_name-and-settings-button {
    &:focus-visible {
      box-shadow: 0 0 0 2px rgba($color-planning-pink, 0.48);
    }

    &:hover {
      background-color: rgba($color-planning-pink, 0.15); // Ask designer for correct color
    }
  }
}

.board-header__access-policy-status {
  color: $color-middle-gray;
  font-size: $text-size--medium;
  font-weight: bold;
  letter-spacing: $letter-spacing--small;
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0 auto;
}

.board-header__name-container {
  position: relative;
}

.board-header__name {
  display: inline-block;

  color: $color-black;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: $letter-spacing--large;
  line-height: 40px;
  margin: 0;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 28vw;
}

[theme="dark"] {
  .board-header__name {
    color: $color-white;
  }
}

.board-header__access-policy-status-icon {
  display: inline;
  vertical-align: bottom;

  width: $icon--medium;
  height: $icon--medium;
  align-self: center;
  margin-right: 2px;
}

.board-header__access-policy-status-icon * {
  color: $color-middle-gray;
}

@media #{$tablet} {
  .board-header {
    left: 60px;
    right: 60px;
  }

  .board-header__name-container {
    min-height: 40px;
    min-width: 240px;
  }
}

@media #{$smartphone} {
  .board-header__users {
    right: 0;
  }

  .board-header__share-button {
    display: none;
  }

  .board-header__link {
    padding: $padding--small;
  }
}
