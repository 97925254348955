@import "constants/style";

.text-input-action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 15px;
  outline: none;
  background: $color-backlog-blue;
  cursor: pointer;

  color: $color-white;
  box-shadow: 0 0 0 0 rgba($color-backlog-blue, 0);
  transition: all 0.08s ease-out;

  &:disabled {
    cursor: default;
    background: $color-middle-gray;
  }
}

.text-input-action:not(:disabled):hover {
  box-shadow: 0 4px 8px 2px rgba($color-backlog-blue, 0.3);
  transform: scale(1.08);
}

.text-input-action:focus-visible {
  box-shadow: 0 0 0 2px rgba($color-backlog-blue, 0.4);
}

.text-input-action > * {
  width: 16px;
  height: 16px;
}

[theme="dark"] {
  .text-input-action:focus-visible {
    box-shadow: 0 0 0 2px $color-white;
  }
}
