@import "constants/style";

$note-input__input-left: 20px;
$note-input__input-right: 40px;

.note-input {
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  position: relative;
  margin-bottom: $margin--default;
  background-color: $color-lighter-gray;
  border-radius: 20px;
  transition: all 0.12s ease-in-out;

  &:hover,
  &:focus-within {
    box-shadow: 0 6px 9px 0 rgba(var(--accent-color-rgb), 0.16);
    outline: 2px solid rgba(var(--accent-color-rgb), 0.5);
  }
}

.note-input__input {
  left: $note-input__input-left;
  right: $note-input__input-right;
  color: $color-black;
  font-size: $text-size--medium;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: $letter-spacing--small;
  padding: $padding--small 0;
  font-family: Raleway, sans-serif;
  background-color: transparent;
  border: none;
  margin-left: $margin--default;
  width: calc(100% - #{$note-input__input-left} - #{$note-input__input-right});
  caret-color: var(--accent-color);
  outline: none;

  &:focus::placeholder {
    color: transparent;
  }
}

.note-input__add-button {
  all: unset;
  cursor: pointer;

  &:hover > .note-input__icon {
    filter: brightness(1.2);
    transform: scale(1.2);
  }

  &:focus > .note-input__icon {
    border-radius: 100%;
    outline: 2px solid rgba(var(--accent-color-rgb), 0.5);
  }
}

.note-input__icon {
  margin-right: $margin--default;
  width: $icon--medium;
  height: $icon--medium;
  color: var(--accent-color);
  align-self: center;
  transition: all 0.08s ease-out;
}

[theme="dark"] {
  .note-input {
    background-color: $color-dark-mode-note;

    &:hover,
    &:focus-within {
      box-shadow: 0 6px 9px 0 #232323;
    }
  }

  .note-input__input {
    color: $color-white;
  }
}
