@import "src/constants/style";
@import "../SettingsDialog";

.participants__container {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 62px;
  overflow: auto;
  flex-grow: 1;
}

.participants__user-list-wrapper {
  overflow: auto;
  width: 100%;
  // overflow-x: hidden; // firefox bug?

  @include scrollbar();
}

.participants__user-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: $color-white;
}

[theme="dark"] {
  .participants__user-list {
    background-color: $color-dark-mode-note;
  }
}

.participants__user {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px $padding--medium;
  height: 48px;
}

.participants__user_avatar-name-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.participants__user_avatar {
  height: 26px;
  width: 26px;
}

.participants__user-name {
  color: $color-black;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--medium;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[theme="dark"] {
  .participants__user-name {
    color: $color-white;
  }
}

.participants__online-mark {
  position: absolute;
  top: 18px;
  left: 19px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $color-grooming-green;
}

.participants__offline-mark {
  position: absolute;
  top: 18px;
  left: 19px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $color-retro-red;
}
