@import "constants/style";

.note-dialog__header {
  font-size: calc(24px + 0.8vw);
  color: $color-white;
  width: 100%;
  margin-bottom: $margin--large;
  letter-spacing: $letter-spacing--large;
  line-height: $line-height--large;
  text-align: center;
  cursor: initial;
  display: flex;
  justify-content: center;

  h2 {
    width: max-content;
    margin: 0;

    &::after {
      content: " ";
      display: block;
      height: 6px;
      background: var(--accent-color);
      border-radius: $border-radius--round;
      margin-top: $margin--default;
    }
  }
}

[theme="dark"] {
  .note-dialog__header > h2::after {
    background: var(--accent-color--dark);
  }
}
