@import "src/constants/style.scss";

.vote-display {
  position: relative;
  width: 85px;
}

.vote-display > * {
  color: $color-white;
  background-color: $color-backlog-blue;
  vertical-align: bottom;
  box-shadow: 0 8px 16px #0057ff25;
}

[theme="dark"] {
  .vote-display > * {
    background-color: $color-planning-pink;
    box-shadow: 0 8px 16px #e2036025;
  }
}
.vote-display > span {
  display: inline-block;
  border-radius: 18px;
  width: 84px;
  cursor: default;
  font-weight: bold;
  line-height: $line-height--large;
  text-align: center;
}
