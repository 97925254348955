@import "constants/style";

.dialog {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  position: fixed;
  border: 3px solid $color-planning-pink;
  background: $color-white-one;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02), 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028), 12.5px 12.5px 10px rgba(0, 0, 0, 0.035), 22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05), 100px 100px 80px rgba(0, 0, 0, 0.07);
}
[theme="dark"] .dialog {
  background: $color-dark-one;
}

@media #{$tablet} {
  .dialog {
    width: 400px;
    height: auto;
    border-radius: 8px;
  }

  .dialog__close-button {
    display: none;
  }
}

.dialog__content {
  padding: $margin--large;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.dialog__header-text {
  border-bottom: solid 3px $color-planning-pink;
  padding-bottom: 8px;
  margin: 0;
}
[theme="dark"] {
  .dialog__header-text {
    color: $color-white;
  }
}

.dialog__close-button {
  position: absolute;
  top: 2px; // some space for the focus border
  right: 2px;
  color: $color-planning-pink;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 0;
  background-color: transparent;
  transform: scale(1);
  transition: all 0.08s ease-in-out;
  border-radius: $border-radius--round;

  &:hover {
    background-color: var(--accent-color--desaturated-light);
  }
  &:focus-visible {
    border: 2px solid var(--accent-color);
    outline: none;
  }
}

.dialog__close-icon {
  width: $icon--extralarge;
  height: $icon--extralarge;
}

.dialog__button {
  position: relative;
  border: none;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  background: $color-white;
  cursor: pointer;
  transition: all 0.08s ease-out;
  outline: none;

  &:hover {
    background-color: rgba(var(--accent-color-rgb), 0.1);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px rgba(var(--accent-color-rgb), 1);
  }

  > label:first-of-type {
    font-size: $text-size--medium;
    color: $color-black;
    letter-spacing: $letter-spacing--medium;
    position: absolute;
    left: 24px;
    top: 16px;
    cursor: pointer;
  }
}

[theme="dark"] {
  .dialog__button {
    background-color: $color-dark-mode-note;
  }
  .dialog__button:hover {
    background-color: $color-dark-mode-note--hover;
  }
  .dialog__button > label {
    color: $color-white;
  }
  .dialog__close-button:hover {
    background-color: var(--accent-color--desaturated-dark);
  }
}
