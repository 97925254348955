@import "src/constants/style";

.accordion-item {
  width: 100%;

  &__header {
    width: 100%;
    height: 48px;
    font-size: $text-size--medium;
    display: flex;
    padding: $padding--small $padding--medium;
    background-color: unset;
    border: none;
    outline: none;
    justify-content: space-between;
    align-items: center;

    &:hover > .accordion-item__header-icon {
      filter: brightness(1.4);
    }

    &:focus-visible {
      background-color: rgba(var(--accent-color-rgb), 0.1);
    }
  }

  &__header-icon {
    height: 24px;
    color: var(--accent-color);
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;

    &--open {
      transform: rotate(-90deg);
    }
  }

  &__body {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    max-height: 100vh;
  }
}

[theme="dark"] {
  .accordion-item__header {
    color: $color-white;
  }
}
