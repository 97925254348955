@import "constants/style.scss";

.user-avatar {
  box-sizing: border-box;
  height: 48px;
  width: 48px;
  border-radius: 100px;
  display: inline-block;
  position: relative;

  #Circle-Background {
    stroke: $color-white-one;
    stroke-width: 24px;
  }
}

.user-avatar__ready {
  position: absolute;
  top: -8px;
  right: -8px;

  width: 24px;
  height: 24px;

  color: $color-grooming-green;

  .circle {
    --dasharray: 55;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    animation: draw 0.45s ease-out, scale 0.3s ease-out;
    transform-origin: 37% 40%;
  }

  .check {
    --dasharray: 20;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    animation: draw 0.6s ease-in-out, scale 0.3s ease-out;
    transform-origin: 37% 40%;
  }
}

.user-ready {
  > .avatar #Circle-Background {
    transform: rotate(90deg) scale(-1, 1);
    stroke: $color-grooming-green;
    stroke-width: 24px;
    --dasharray: 760;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    animation: draw 0.45s ease-out;
    transform-origin: 45.5% 43%;
  }
}

.user-avatar__raised-hand {
  position: absolute;
  top: -8px;
  right: -8px;

  width: 24px;
  height: 24px;

  color: $color-backlog-blue;
  animation: wave 0.8s ease-in-out;
  transform-origin: bottom left;

  .wave {
    --dasharray: 12;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    animation: draw 0.8s ease-in-out;
  }
}

[theme="dark"] {
  .user-avatar__raised-hand {
    color: $color-planning-pink;
  }

  .user-avatar #Circle-Background {
    stroke: $color-dark-one;
  }

  .user-ready #Circle-Background {
    stroke: $color-grooming-green;
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: var(--dasharray);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg) scale(0);
  }
  25% {
    transform: rotate(14deg) scale(1.14);
  }
  50% {
    transform: rotate(-16deg);
  }
  75% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
